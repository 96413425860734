import React from "react";
import { $DataType } from "../enum.dto";
import BlockHashDetails from "./BlockHashDetails";
import SignatureHashDetails from "./SignatureHashDetails";
import ValidationsBlock from "../Blocks/ValidationsBlock";
import DocumentNameDetails from "./DocumentNameDetails";
import "./App.css";
import BlockList from "../Blocks/BlockList";
import ErrorBlock from "../Blocks/ErrorBlock";
import BBHash from "./BBHash";
import { getChainName } from "../utilities";
import BlockBBHashDetails from "./BlockBBHashDetails";

function DataDetails(props: any) {
  if (props.type === $DataType.blockHash || props.type === $DataType.txId) {
    return (
      <>
        <BlockHashDetails data={props?.details} />
        {props?.details?.block?.documents?.length > 0 ? (
          <BlockList data={props?.details?.block} />
        ) : (
          ""
        )}
      </>
    );
  } else if (props.type === $DataType.blockBBHash) {
    return (
      <>
        <BlockBBHashDetails data={props?.details} />
      </>
    );
  } else if (props.type === $DataType.signaturehash) {
    return (
      <>
        <SignatureHashDetails data={props?.details} />
        {props?.details?.signature?.validations?.length > 0 ? (
          <ValidationsBlock validations={props?.details} />
        ) : (
          ""
        )}
      </>
    );
  } else if (props.type === $DataType.BBHash) {
    const validations = props?.details?.validations.map((item: any) => {
      return {
        blockchain: getChainName(item.name),
        blockHash: item.rootHash,
        datetime: item.datetime,
      };
    });
    let document = {
      document: {
        name: "",
        validations: validations || [],
      },
    };
    return (
      <>
        <BBHash data={props?.details} />
        {props?.details?.validations?.length > 0 ? (
          <ValidationsBlock validations={document} BB={true} />
        ) : (
          <ErrorBlock
            title="No validations found"
            message="The document is currently awaiting notarization. Please check back in a few minutes."
          />
        )}
      </>
    );
  } else {
    return (
      <>
        <DocumentNameDetails data={props?.details} />
        {props?.details?.document?.validations?.length > 0 ? (
          <ValidationsBlock validations={props?.details} />
        ) : (
          <ErrorBlock
            title="No validations found"
            message="The document is currently awaiting notarization. Please check back in a few minutes."
          />
        )}
      </>
    );
  }
}
export default DataDetails;
