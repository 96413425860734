import { $BlockchainsEnum } from "./enum.dto";
import {
  faFilePdf,
  faFileSignature,
  faFilePowerpoint,
  faFileCsv,
  faFileAudio,
  faFileLines,
  faFileVideo,
  faIcons,
  faImage,
} from "@fortawesome/free-solid-svg-icons";

export const getTrimFilename = (name: string) => {
  let type = name?.split(".")[1];
  let start = name?.split("")?.slice(0, 4)?.join("");
  let end = name
    ?.split(".")[0]
    ?.split("")
    ?.reverse()
    ?.slice(0, 4)
    ?.reverse()
    ?.join("");
  return `${start}***${end}.${type}`;
};

export const getTrimHash = (hash: string, nr: number = 4) => {
  let start = hash?.split("")?.slice(0, nr)?.join("");
  let end = hash?.split("")?.reverse()?.slice(0, nr)?.reverse()?.join("");
  return `${start}...${end}`;
};

export const getShortString = (
  string: string,
  number: number = 20,
  cut = "end"
) => {
  let newString = string;
  switch (cut) {
    case "end":
      newString = string.slice(0, number);
      return `${newString}...`;
    case "middle":
      const start = string.slice(0, Math.floor(number / 2));
      const end = string.slice(-Math.ceil(number / 2));
      return `${start}...${end}`;
    default:
      newString = string.slice(0, number);
      return `...${newString}`;
  }
};

export const getCurrentUrl = () => {
  return window.location.href.split("/?")[0];
};

export const getExplorerRef = (transaction: string, blockchain: string) => {
  switch (blockchain) {
    case $BlockchainsEnum.EOS:
      return `https://bloks.io/transaction/${transaction}`;
    case $BlockchainsEnum.Bitcoin:
      return `https://live.blockcypher.com/btc/tx/${transaction}`;
    case $BlockchainsEnum.Ethereum:
      return `https://etherscan.io/tx/${transaction}`;
    case $BlockchainsEnum.Solana:
      return `https://explorer.solana.com/tx/${transaction}`;
    case $BlockchainsEnum.Ton:
      return `https://tonwhales.com/explorer/address/${transaction}`;
    default:
      return `https://live.blockcypher.com/btc/tx/${transaction}`;
  }
};

export const getDocumentTypeIcon: any = (key: string) => {
  switch (key) {
    case "pdf":
      return faFilePdf;
    case "mp3":
      return faFileAudio;
    case "wav":
      return faFileAudio;
    case "mp4":
      return faFileVideo;
    case "webm":
      return faFileVideo;
    case "svg":
      return faIcons;
    case "csv":
      return faFileCsv;
    case "pptx":
      return faFilePowerpoint;
    case "ppt":
      return faFilePowerpoint;
    case "jpg":
      return faImage;
    case "jpeg":
      return faImage;
    case "png":
      return faImage;
    default:
      return faFileLines;
  }
};

export function checkHashFormat(
  hash: string,
  algorithm: "sha256" | "md5" = "sha256"
) {
  // Regular expression for a valid SHA-256 hash
  const regexConfig = {
    sha256: /^[a-fA-F0-9]{64}$/,
    md5: /^[a-fA-F0-9]{32}$/,
  };

  // Test if the provided hash matches the SHA-256 regex
  return regexConfig[algorithm].test(hash);
}

export const getChainName = (name: string) => {
  switch (name.toUpperCase()) {
    case 'EOS':
      return $BlockchainsEnum.EOS;
    case 'BTC':
      return $BlockchainsEnum.Bitcoin;
    case 'ETH':
      return $BlockchainsEnum.Ethereum;
    case 'SOL':
      return $BlockchainsEnum.Solana;
    case 'TON':
      return $BlockchainsEnum.Ton;
    default:
      return ;
  }
}

export function addZero(number: number) {
  return number < 10 ? '0' + number : number;
}

export function getFullDate(inputDate: Date){
  const giorniSettimana = [
      'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
  ];
  
  const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
  ];
  const weekDay = giorniSettimana[inputDate.getUTCDay()];
  const month = months[inputDate.getUTCMonth()];
  const day = inputDate.getUTCDate();
  const year = inputDate.getUTCFullYear();
  const hour = addZero(inputDate.getUTCHours());
  const minute = addZero(inputDate.getUTCMinutes());
  const timeZone = 'UTC+1';
  
  return `\n${weekDay}, ${month} ${day},\n${year}, ${hour}:${minute} ${timeZone}`;
  }