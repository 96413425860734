import React from "react";
import bgVideo from "../../assets/background.mp4";
import "./App.css";

function BackgroundVideo() {
  return (
    <video autoPlay muted loop id="myVideo" className="BGVideo">
      <source src={bgVideo} type={"video/mp4"} />
      Your browser does not support HTML5 video.
    </video>
  );
}

export default BackgroundVideo;
