import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import {
  getChainName,
  getExplorerRef,
  getShortString,
  getFullDate,
} from "../utilities";
import "./App.css";

function BlockBBHashDetails(props: any) {
  return (
    <div className="DetailsWrapper">
      {/*<div className="Summary Col">
        <span className="Title">Activity</span>
      </div>*/}
      {/*<div className="VerticalSpacer"></div>*/}
      <div className="Additionals Col">
        <span className="Title">File information</span>
        <div className="Field">
          <span>Transaction:</span>
          <a
            className="url"
            href={getExplorerRef(
              props.data.transaction.txId,
              getChainName(props.data.transaction.name || "") || ""
            )}
            target="_blank"
            rel="noreferrer noopener"
            title={getChainName(props.data.transaction.name || "")}
          >
            {getShortString(props.data.transaction.txId, 20, "end")}
            <FontAwesomeIcon className="icon" icon={faArrowUpRightFromSquare} />
          </a>
        </div>
        <div className="Field">
          <span>Blockchain:</span>
          <span>{props.data.transaction.name}</span>
        </div>
        <div className="Field">
          <span>Datetime:</span>
          <span>{getFullDate(new Date(props.data.transaction.datetime))}</span>
        </div>
        <div className="Field">
          <span>Combined Hash:</span>
          <span title={props.data.transaction.rootHash}>
            {getShortString(props.data.transaction.rootHash, 20, "middle")}
            <FontAwesomeIcon
              icon={faCopy}
              onClick={() =>
                navigator.clipboard.writeText(props.data.transaction.rootHash)
              }
            />
          </span>
        </div>
      </div>
    </div>
  );
}
export default BlockBBHashDetails;
