import React, { useState, useEffect } from "react";
import {
  getDocumentData,
  getBlockHashData,
  getSignatureHashData,
  getBBData,
  getBlockBBHashData,
} from "../Api";

import { $DataType } from "../enum.dto";
import "./App.css";
// import BlockList from "../Blocks/BlockList";
import ErrorBlock from "../Blocks/ErrorBlock";

import Loader from "./Loader";
import Header from "./Header";
import DataDetails from "./DataDetails";
import BackgroundVideo from "./BackgroundVideo";
import SearchBar from "./SearchBar";
import MultipleDocuments from "./MultipleDocuments";
import { getCurrentUrl } from "../utilities";

function App() {
  const header: any = {
    subtitle: "Documents",
    title: "EXPLORER",
    description: "Search transactions, blocks or document hash ",
  };

  const [searchValue, setSearchValue] = useState("");
  const [view, setView] = useState("");
  const [isFirstLoad, setFirstLoad] = useState(true);
  const [data, setData] = useState<any>(null);
  const [chain, setChain] = useState("");
  const [version, setVersion] = useState<number | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<null | {
    message: string;
    title?: string;
  }>(null);

  useEffect(() => {
    if (isFirstLoad) {
      setLoading(true);
      let tmp = window.location.href;
      let searchParam = tmp?.split("?", 2)[1]?.split("=", 2)[0];
      let searchHash = "";
      let blockchain = "";
      let docName = "";
      let lastVersion = false;
      let txId = "";

      if (!searchParam) {
        setView("");
        setLoading(false);
        return;
      }

      if (searchParam === $DataType.blockHash && tmp.includes("&blockchain=")) {
        searchHash = tmp?.split("=")[1].split("&")[0];
        blockchain = tmp?.split("&blockchain=")[1].split("&")[0];
        docName = tmp.split("&id=")[1];
      } else if (searchParam === $DataType.blockBBHash) {
        searchHash = tmp?.split("=")[1].split("&")[0];
      } else {
        searchHash = tmp?.split("?", 2)[1]?.split("=")[1].split("&")[0];
        if (tmp.includes("&version=latest")) lastVersion = true;
      }

      setSearchValue(searchHash);
      setView(searchParam);
      setChain(blockchain);

      if (searchParam && searchHash) {
        loadData(
          searchHash,
          searchParam,
          blockchain,
          docName,
          lastVersion,
          searchHash
        );
      }

      setFirstLoad(false);
    }
  }, [isFirstLoad]);

  async function loadData(
    hash: string,
    type: string,
    blockchain: string,
    docName: string,
    lastVersion: boolean,
    txId: string
  ) {
    let response;

    switch (type) {
      case "blockHash":
        response = await getBlockHashData(hash, blockchain, docName);
        break;
      case "blockBBHash":
        response = await getBlockBBHashData(txId);
        break;
      case "signatureHash":
        response = await getSignatureHashData(hash);
        break;
      case "documentHash":
        response = await getDocumentData(hash, lastVersion);
        break;
      case "txId":
        response = await getBlockHashData(hash, blockchain, docName, hash);
        break;
      case "BBHash":
        response = await getBBData(hash);
        break;

      default:
        response = null;
        break;
    }

    if (!response) {
      setError({ message: "Unknown error", title: "Unable to load data:" });
      return;
    }

    try {
      const { data, status } = response;
      if (status !== 200) {
        setError({
          message:
            "Unable to load the information for the given hash. Please make sure that the hash corresponds to any transaction, block hash or document hash.",
          title: "Unable to load data",
        });
        setLoading(false);
        return;
      }

      setData(data);
      setLoading(false);
    } catch (err) {
      setData(null);
      setError({ message: "Unknown error", title: "Unable to load data:" });

      setLoading(false);
    }
  }

  const renderDocumentChoice = () => {
    const orderedDocuments = data?.document?.sort(
      (a: any, b: any) => b.version - a.version
    );

    return (
      <MultipleDocuments
        onSetVersion={setVersion}
        documents={orderedDocuments}
      />
    );
  };

  useEffect(() => {
    if (version === null || data?.document?.version === version) return;
    const newData = {
      ...data,
      document: data.document.find((d: any) => d.version === version),
    };
    setData(newData);
  }, [version]);

  const handleInputChange = (value: string) => {
    setError(null);
    setData(null);
    setSearchValue(value);
  };

  return (
    <div className="App">
      <BackgroundVideo />
      <div className="Content">
        <Header data={header} />
        <SearchBar
          setLoading={setLoading}
          value={searchValue}
          onSearchStringChange={handleInputChange}
        />
        {!!data && !isLoading ? (
          view === "documentHash" && data?.document.length && !version ? (
            renderDocumentChoice()
          ) : (
            <DataDetails blockchain={chain} type={view} details={data} />
          )
        ) : (
          ""
        )}
        {isLoading ? <Loader /> : ""}
        {error ? <ErrorBlock {...error} /> : ""}
        {!!data && version !== null && (
          <span className="back" onClick={() => window.location.reload()}>
            choose version
          </span>
        )}
        {!!data && data.block?.currDocument && (
          <span
            className="back"
            onClick={() => {
              window.location.href = `${getCurrentUrl()}/?documentHash=${
                data.block?.currDocument.hash
              }`;
            }}
          >
            back home
          </span>
        )}
      </div>
    </div>
  );
}

export default App;
