import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import {
  checkHashFormat,
  getExplorerRef,
  getShortString,
  getTrimHash,
  getFullDate,
} from "../utilities";
import "./App.css";

function BlockHashDetails(props: any) {
  const {
    data: { block },
  } = props;

  const { currDocument } = block;
  return (
    <div className="DetailsWrapper">
      {" "}
      {currDocument && (
        <div className="Summary Col">
          <span className="Title">Activity</span>
          {currDocument ? (
            <>
              <div className="Field">
                <span>File Hash:</span>
                <span title={currDocument.hash}>
                  {checkHashFormat(currDocument.hash)
                    ? getTrimHash(currDocument.hash, 15)
                    : currDocument.hash}
                  <FontAwesomeIcon
                    icon={faCopy}
                    onClick={() =>
                      navigator.clipboard.writeText(currDocument.hash)
                    }
                  />
                </span>
              </div>
              {currDocument.hasBiometric ? (
                <div className="Field">
                  <span>Signature Hash:</span>
                  <span title={currDocument.signatureHash}>
                    {getShortString(currDocument.signatureHash, 25, "middle")}
                    <FontAwesomeIcon
                      icon={faCopy}
                      onClick={() =>
                        navigator.clipboard.writeText(
                          currDocument.signatureHash
                        )
                      }
                    />
                  </span>
                </div>
              ) : (
                <div className="Field">
                  <span>Biometric Signatures:</span>
                  <span>{currDocument.signaturesCount}</span>
                </div>
              )}

              {currDocument.activitiesHash && (
                <div className="Field">
                  <span>Activities hash:</span>
                  <span title={currDocument.activitiesHash}>
                    {getShortString(currDocument.activitiesHash, 25, "middle")}
                    <FontAwesomeIcon
                      icon={faCopy}
                      onClick={() =>
                        navigator.clipboard.writeText(
                          currDocument.activitiesHash
                        )
                      }
                    />
                  </span>
                </div>
              )}
            </>
          ) : (
            <div className="Field">
              <span>
                Expand the list below to view all the hashes in this block
              </span>
            </div>
          )}
        </div>
      )}
      <div className="VerticalSpacer"></div>
      <div className="Additionals Col">
        <span className="Title">File information</span>
        <div className="Field">
          <span>Transaction:</span>
          <a
            className="url"
            href={getExplorerRef(block.transaction, block.blockchain)}
            target="_blank"
            rel="noreferrer noopener"
            title={block.transaction}
          >
            {getShortString(block.transaction, 20, "end")}
            <FontAwesomeIcon className="icon" icon={faArrowUpRightFromSquare} />
          </a>
        </div>
        <div className="Field">
          <span>Blockchain:</span>
          <span>{block.blockchain}</span>
        </div>
        <div className="Field">
          <span>Datetime:</span>
          <span>{getFullDate(new Date(block.datetime))}</span>
        </div>
        <div className="Field">
          <span>Combined Hash:</span>
          <span title={block.hash}>
            {getShortString(block.hash, 20, "middle")}
            <FontAwesomeIcon
              icon={faCopy}
              onClick={() =>
                navigator.clipboard.writeText(currDocument.blockHash)
              }
            />
          </span>
        </div>
      </div>
    </div>
  );
}
export default BlockHashDetails;
