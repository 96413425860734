import React, { useState } from "react";
import "./App.css";
import searchIcon from "../../assets/search.svg";
import { checkHashFormat, getCurrentUrl } from "../utilities";
import {
  getBBData,
  getBlockBBHashData,
  getBlockHashData,
  getDocumentData,
} from "../Api";

function SearchBar({
  onSearchStringChange,
  value,
  setLoading,
}: {
  onSearchStringChange: (value: string) => void;
  value: string;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [searchHash, setSearchHash] = useState(value);

  const handleSearchSubmit = async (
    e:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => {
    e.preventDefault();
    const currentUrl = getCurrentUrl();

    const hash = value || searchHash;
    if (!hash.trim()) return;

    const isHash = checkHashFormat(hash) || checkHashFormat(hash, "md5");

    let response = await getBlockBBHashData(hash || "");
    let url = "";
    console.log(response);
    setLoading(true);

    if (response.status === 200) {
      url = `${currentUrl}?txId=${hash}`;
      window.location.href = url;
      setLoading(false);
      return;
    }

    if (!isHash) {
      window.location.href = `${currentUrl}?txId=${hash}`;
      return;
    }
    console.log("here");

    response = await getBBData(hash);

    console.log("post req");

    url = `${currentUrl}?BBHash=${hash}`;
    if (response.status === 200) {
      window.location.href = url;
      setLoading(false);
      return;
    }

    response = await getDocumentData(hash, false);

    url = `${currentUrl}?documentHash=${hash}`;
    if (response.status === 200) {
      window.location.href = url;
      setLoading(false);
      return;
    }

    response = await getBlockHashData(hash, "", "", hash);
    setLoading(false);

    if (response.status === 200) {
      url = `${currentUrl}?txId=${hash}`;
      window.location.href = url;
      return;
    }

    url = `${currentUrl}?blockHash=${hash}`;
    window.location.href = url;
    return;
  };

  function handleInputChange(e: any) {
    e.preventDefault();
    setSearchHash(e.target.value);
    onSearchStringChange(e.target.value as string);
  }

  return (
    <>
      <form
        onSubmit={(e) => {
          handleSearchSubmit(e);
        }}
        className="SearchBar"
      >
        <input
          placeholder="Insert a document hash, transaction ID or a block hash"
          onChange={handleInputChange}
          value={value}
        />
        <img
          alt="Explore"
          src={searchIcon}
          onClick={(e) => handleSearchSubmit(e)}
        ></img>
      </form>
    </>
  );
}

export default SearchBar;
