function ErrorBlock({
  message,
  title = "Error loading data:",
}: {
  message: string;
  title?: string;
}) {
  return (
    <div className="ErrorMessage">
      <span className="title">{title}</span>
      <span>{message}</span>
    </div>
  );
}

export default ErrorBlock;
