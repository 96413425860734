import React from "react";
import logo from "../../assets/logo.svg";
import "./App.css";

function Header(props: any) {
  return (
    <div className="Header">
      <img src={logo} alt="" className="logo" />
      <span className="Subtitle">{props.data.subtitle}</span>
      <span className="Title">{props.data.title}</span>
      <span className="Description">{props.data.description}</span>
    </div>
  );
}

export default Header;
