import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faCopy,
  faVideoCamera,
} from "@fortawesome/free-solid-svg-icons";
import {
  getTrimFilename,
  getTrimHash,
  getCurrentUrl,
  getFullDate,
} from "../utilities";

function SignatureHashDetails(props: any) {
  let data = props.data.signature;
  return (
    <div className="DetailsWrapper">
      <div className="Summary Col">
        <span className="Title">Activity</span>
        <div className="Field">
          <span>Original Video:</span>
          <span className="info" title={data?.originalVideo}>
            {getTrimFilename(data?.originalVideo)}
            <FontAwesomeIcon className="icon" icon={faVideoCamera} />
          </span>
        </div>
        <div className="Field">
          <span>Datetime:</span>
          <span className="info" title={data?.originalVideo}>
            {getFullDate(new Date(data.datetime))}
          </span>
        </div>
        <div className="Field">
          <span>Status:</span>
          <span>{data?.status}</span>
        </div>
      </div>
      <div className="VerticalSpacer"></div>
      <div className="Additionals Col">
        <span className="Title">File information</span>
        <div className="Field">
          <span>Document Name:</span>
          <a
            title={data?.documentHash}
            href={`${getCurrentUrl()}/?documentHash=${data?.documentHash}`}
          >
            {getTrimFilename(data?.documentHash)}
            <FontAwesomeIcon className="icon" icon={faArrowUpRightFromSquare} />
          </a>
        </div>
        <div className="Field">
          <span>Document Hash:</span>
          <span className="info" title={data?.documentHash}>
            {getTrimHash(data?.documentHash)}
            <FontAwesomeIcon
              icon={faCopy}
              onClick={() => navigator.clipboard.writeText(data?.documentHash)}
            />
          </span>
        </div>
        <div className="Field">
          <span>Signature Hash:</span>
          <span className="info" title={data?.hash}>
            {getTrimHash(data?.hash)}
          </span>
        </div>
      </div>
    </div>
  );
}

export default SignatureHashDetails;
