import ton from "../../assets/ton.png";
import sol from "../../assets/sol.png";
import btc from "../../assets/btc.png";
import eth from "../../assets/eth.png";
import eos from "../../assets/eos.png";
import { getCurrentUrl } from "./../utilities";
function ValidationsBlock(props: any) {
  const {
    validations: { document },
  } = props;
  return (
    <div className="GenericWrapper">
      <div className="Body">
        {document.validations.map((e: any) => {
          return (
            <BlockChainLogo
              docName={document.name}
              key={e.blockchain}
              type={e.blockchain}
              BB={props.BB || false}
              data={e}
            />
          );
        })}
      </div>
    </div>
  );
}

export function BlockChainLogo(props: any) {
  const Logos: any = {
    Ton: ton,
    Solana: sol,
    Bitcoin: btc,
    EOS: eos,
    Ethereum: eth,
  };
  let type = props.type;
  let img = Logos[type as any];
  let blockUrl = props.BB
    ? `${getCurrentUrl()}/?blockBBHash=${props?.data?.blockHash}&leafHash=`
    : `${getCurrentUrl()}/?blockHash=${
        props?.data?.blockHash
      }&blockchain=${type}&id=${props.docName}`;
  return (
    <a href={blockUrl}>
      <img
        title={type}
        src={img}
        alt=""
        draggable="false"
        className="ValidationLogo"
      />
    </a>
  );
}

export default ValidationsBlock;
