import axios from "axios";
import { getChainName } from "./utilities";
const endpoint = "https://api.notarify.io";
const endpointBB = "https://blackbox.notarify.io";
//const endpoint = "http://127.0.0.1:8000";

export async function getDocumentData(
  hash: string,
  lastVersion: boolean
): Promise<any> {
  try {
    const response = await axios.get(
      `${endpoint}/explorer/getBasicData/?documentHash=${hash}&lastVersion=${lastVersion}`
    );
    return response;
  } catch (err: any) {
    if (err.response) {
      return {
        status: err.response?.status,
        data: err.response?.data,
      };
    } else {
      return {
        status: 500,
        data: { status: "Internal server error", details: "Unknown error" },
      }; // Rethrow the error for other error scenarios// Rethrow the error for other error scenarios
    }
  }
}

export async function getBBData(
  hash: string
): Promise<any> {
  try {
    const response = await axios.get(
      `${endpointBB}/stamp/info/BBhash/${hash}`
    );
    return response;
  } catch (err: any) {
    if (err.response) {
      return {
        status: err.response?.status,
        data: err.response?.data,
      };
    } else {
      return {
        status: 500,
        data: { status: "Internal server error", details: "Unknown error" },
      }; // Rethrow the error for other error scenarios// Rethrow the error for other error scenarios
    }
  }
}

export async function getBlockHashData(
  blockHash: string,
  blockchain: string,
  docName: string,
  txId?: string
): Promise<any> {
  try {
    const params: {
      txId?: string;
      id?: string;
      blockHash?: string;
    } = { txId: txId };
    if (!txId) {
      delete params.txId;
      params.blockHash = blockHash;
      params.id = docName;
    } else {
      try {
        const response = await axios.get(
          `${endpointBB}/stamp/verify/txId/?txId=${txId}`
        );
        if (response.status === 200) {
          return {
            status: 200,
            data: {
            block: {
              hash: response.data.transaction.rootHash,
              datetime: response.data.transaction.datetime,
              transaction: response.data.transaction.txId,
              documents: [],
              signatures: [],
              currDocument: null,
              blockchain: getChainName(response.data.transaction.name)
            }
          }}
        }
      } catch (error) {} 
      }
    const response = await axios.get(`explorer/block/getOne/`, {
      params,
      baseURL: endpoint,
    });

    return response;
  } catch (err: any) {
    if (err.response) {
      return {
        status: err.response?.status,
        data: err.response?.data,
      };
    } else {
      return {
        status: 500,
        data: { status: "Internal server error", details: "Unknown error" },
      }; // Rethrow the error for other error scenarios
    }
  }
}

export async function getBlockBBHashData(
  txId?: string
): Promise<any> {
  try {
    let response = await axios.get(
      `${endpointBB}/stamp/verify/hash/?rootHash=${txId}`
    );
    return response;
  } catch (err: any) {
    if (err.response) {
      return {
        status: err.response?.status,
        data: err.response?.data,
      };
    } else {
      return {
        status: 500,
        data: { status: "Internal server error", details: "Unknown error" },
      }; // Rethrow the error for other error scenarios
    }
  }
}

export async function getSignatureHashData(
  signatureHash: string
): Promise<any> {
  return axios.get(
    `${endpoint}/signature/getBasicData/?signatureHash=${signatureHash}`
  );
}
