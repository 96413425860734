import "./App.css";
import DocumentNameDetails from "./DocumentNameDetails";

function MultipleDocuments({
  documents,
  onSetVersion,
}: {
  documents: any[];
  onSetVersion: any;
}) {
  return (
    <div className="DetailsWrapper">
      <div className="multiple-document">
        <h3>We have found multiple versions for this document hash</h3>
        <p>
          Documents signed with video-biometric signature keep their original
          hash and are tracked via versions. Please choose the version of the
          document you want to explore:
        </p>
      </div>
      <div className="documents-grid">
        {documents.map((doc, idx) => (
          <DocumentNameDetails
            onSetVersion={onSetVersion}
            docName={doc.name}
            key={idx}
            type="documenthash"
            data={{ document: doc }}
          />
        ))}
      </div>
    </div>
  );
}
export default MultipleDocuments;
